import Glider from "react-glider";
import { CollectionCard, CollectionCardProps } from "../cards/CollectionCard";
interface FeaturedCollectionsProps {
  collections: CollectionCardProps[];
}

export const FeaturedCollections = ({
  collections,
}: FeaturedCollectionsProps) => {
  return (
    <div className="relative">
      <Glider
        draggable
        hasArrows
        hasDots={collections.length > 4}
        className="!py-5"
        arrows={{
          next: "#ft-rightArrow",
          prev: "#ft-leftArrow",
        }}
        slidesToShow="auto"
        itemWidth={290}
      >
        {collections.map(
          ({
            name,
            amount,
            author,
            artImages,
            collectionAddress,
            verified,
          }) => (
            <CollectionCard
              key={collectionAddress}
              name={name}
              author={author}
              amount={amount}
              artImages={artImages}
              collectionAddress={collectionAddress}
              verified={verified}
            />
          )
        )}
      </Glider>

      <div
        id="ft-leftArrow"
        className="group absolute top-1/2 -left-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base drop-shadow-md sm:-left-6"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="fill-jacarta-700 group-hover:fill-accent"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
        </svg>
      </div>
      <div
        id="ft-rightArrow"
        className="group absolute top-1/2 -right-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base drop-shadow-md sm:-right-6"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="fill-jacarta-700 group-hover:fill-accent"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
        </svg>
      </div>
    </div>
  );
};
