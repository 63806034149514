import Image from "next/image";
import cs from "classnames";
import { ImageFit } from "database";

import { MarketplaceLink } from "@components/MarketplaceLink";

const MAIN_IMAGE_SIZE = {
  width: 152,
  height: 242,
};
const SECONDARY_IMAGE_SIZE = {
  width: 68,
  height: 74,
};
const USER_IMAGE_SIZE = {
  width: 20,
  height: 20,
};

export interface CollectionCardProps {
  name: string;
  author: {
    name: string;
    image: string;
    address: string;
  };
  collectionAddress: string;
  amount: string;
  artImages: {
    main: {
      url: string;
      imageFit: ImageFit;
    };
    secondary: {
      url: string;
      imageFit: ImageFit;
    }[];
  };
  verified: boolean | null;
}

export const CollectionCard = ({
  name,
  author,
  amount,
  artImages,
  collectionAddress,
  verified,
}: CollectionCardProps) => {
  return (
    <article className="h-full">
      <MarketplaceLink
        href={`/${collectionAddress}`}
        className="mx-auto block w-min"
      >
        <div className="w-min rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
          <div className="relative flex space-x-3">
            <div
              style={{
                width:
                  artImages.secondary.length === 0
                    ? MAIN_IMAGE_SIZE.width + SECONDARY_IMAGE_SIZE.width
                    : MAIN_IMAGE_SIZE.width,
                height: MAIN_IMAGE_SIZE.height,
              }}
              className="relative overflow-hidden rounded-[0.625rem] ring-1 ring-jacarta-100"
            >
              <Image
                src={artImages.main.url}
                alt="item 1"
                className={cs({
                  "object-cover": artImages.main.imageFit === ImageFit.COVER,
                  "object-contain":
                    artImages.main.imageFit === ImageFit.CONTAIN,
                })}
                loading="lazy"
                fill
              />
            </div>

            {verified && (
              <div
                className="absolute left-[88%] top-[95%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green-500 dark:border-jacarta-600"
                data-tippy-content="Verified Token"
                title="Verified Token"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="h-[.875rem] w-[.875rem] fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                </svg>
              </div>
            )}

            {artImages.secondary.length > 0 && (
              <span className="flex flex-col space-y-[0.625rem]">
                {artImages.secondary.map((image) => (
                  <div
                    key={image.url}
                    className="relative overflow-hidden rounded-[0.625rem] ring-1 ring-jacarta-100"
                    style={{
                      width: SECONDARY_IMAGE_SIZE.width,
                      height: SECONDARY_IMAGE_SIZE.height,
                    }}
                  >
                    <Image
                      src={image.url}
                      alt=""
                      className={cs({
                        "object-cover": image.imageFit === ImageFit.COVER,
                        "object-contain": image.imageFit === ImageFit.CONTAIN,
                      })}
                      fill
                    />
                  </div>
                ))}
              </span>
            )}
          </div>
          <div className="mt-4 block font-display text-base text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent">
            {name}
          </div>
          <div className="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
            <div className="flex flex-wrap items-center">
              <MarketplaceLink
                href={`/user/${author.address}`}
                className="relative mr-2 shrink-0"
              >
                <Image
                  src={author.image}
                  alt="owner"
                  className="h-5 w-5 rounded-full"
                  width={USER_IMAGE_SIZE.width}
                  height={USER_IMAGE_SIZE.height}
                />
              </MarketplaceLink>
              <span className="mr-1 dark:text-jacarta-400">by</span>
              <MarketplaceLink href={`/user/${author.address}`}>
                <span className="text-accent">{author.name}</span>
              </MarketplaceLink>
            </div>
            <span className="text-sm dark:text-jacarta-300">
              {amount} Items
            </span>
          </div>
        </div>
      </MarketplaceLink>
    </article>
  );
};
